import React from 'react';
import UpgradedPlanSummary from './UpgradedPlanSummary';
import LegacyPlanSummary from './LegacyPlanSummary';

const PlanSummary = (props) => {
  const {
    accountLimitOverages,
    archivalStorageInfo,
    isCheckoutFlowV2Enabled,
    accountId,
    continueFlow,
    isOnTrial,
    isSubmitting,
    isAccountOnPlanWithUserMax,
    isFreePlan,
    isLegacy,
    isPlanPreSelected,
    memberInfo,
    movingToCheaperPlan,
    nextBillingDate,
    onCtaClick,
    promotionExpiresAt,
    selectedPlan,
    restartFlow,
    seatUnit,
    shouldDisplayCurrentPlan,
    storageInfo,
    planQuote,
    onCancel,
    isManagingStorage,
    showDetailsOnly,
    billingCountry,
    hasVat,
  } = props;
  return isCheckoutFlowV2Enabled ? (
    <UpgradedPlanSummary
      data-testid="upgraded-plan-summary"
      accountId={accountId}
      continueFlow={continueFlow}
      isOnTrial={isOnTrial}
      isSubmitting={isSubmitting}
      isFreePlan={isFreePlan}
      isPlanPreSelected={isPlanPreSelected}
      onCtaClick={onCtaClick}
      promotionExpiresAt={promotionExpiresAt}
      selectedPlan={selectedPlan}
      restartFlow={restartFlow}
      shouldDisplayCurrentPlan={shouldDisplayCurrentPlan}
      planQuote={planQuote}
      onCancel={onCancel}
      isManagingStorage={isManagingStorage}
      showDetailsOnly={showDetailsOnly}
      billingCountry={billingCountry}
      hasVat={hasVat}
    />
  ) : (
    <LegacyPlanSummary
      accountId={accountId}
      accountLimitOverages={accountLimitOverages}
      continueFlow={continueFlow}
      isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}
      isLegacy={isLegacy}
      isOnTrial={isOnTrial}
      isSubmitting={isSubmitting}
      memberInfo={memberInfo}
      movingToCheaperPlan={movingToCheaperPlan}
      nextBillingDate={nextBillingDate}
      onCtaClick={onCtaClick}
      seatUnit={seatUnit}
      selectedPlan={selectedPlan}
      storageInfo={storageInfo}
      archivalStorageInfo={archivalStorageInfo}
      restartFlow={restartFlow}
      shouldDisplayCurrentPlan={shouldDisplayCurrentPlan}
    />
  );
};

export default PlanSummary;
